// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";

function edit(id) {
  return "/dashboard/users/" + ID.toString(id) + "/edit";
}

function subscribe(id) {
  return "/dashboard/users/" + ID.toString(id) + "/subscribe";
}

function unsubscribe(id) {
  return "/dashboard/users/" + ID.toString(id) + "/unsubscribe";
}

var Dashboard = {
  index: "/dashboard/users",
  edit: edit,
  cloudRack: "/dashboard/users/cloudrack",
  subscribe: subscribe,
  unsubscribe: unsubscribe
};

var Dashboard$1 = {
  edit: "/users/dashboard/edit-profile",
  cloudRack: "/users/dashboard/cloudrack",
  orders: "/users/dashboard/orders",
  invitations: "/users/dashboard/invitations",
  notifications: "/users/dashboard/notifications"
};

var Personal = {
  Dashboard: Dashboard$1
};

var signIn = "/users/sign_in";

var signUp = "/users/sign_up";

export {
  signIn ,
  signUp ,
  Dashboard ,
  Personal ,
}
/* ID Not a pure module */

// extracted by mini-css-extract-plugin
export var column = "Tooltip__column__Odw27";
export var container = "Tooltip__container__NdZkO";
export var containerWithoutArrow = "Tooltip__containerWithoutArrow__JZCkC";
export var positionBottom = "Tooltip__positionBottom__rj_Mm";
export var positionLeft = "Tooltip__positionLeft__v4glT";
export var positionRight = "Tooltip__positionRight__sQBLD";
export var positionTop = "Tooltip__positionTop__qJKl0";
export var row = "Tooltip__row__zvQN6";
export var tooltip = "Tooltip__tooltip__HPk6d";
export var trigger = "Tooltip__trigger__x8V7Y";